import Error404 from "../components/Error404";

/**
 * @typedef {object} Props
 * @property {any} [value]
 *
 * @param {Props} props
 */
function Page(props) {
  return <Error404 />;
}

export default Page;
