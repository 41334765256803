import { Box, Button, VStack } from "@chakra-ui/react";
import generateFrontPath from "@raiden/library/libraries/utils/generateFrontPath";
import { FormattedMessage, useIntl } from "react-intl";
import { PageLayout } from "../PageLayout";
import { Splash } from "../Splash";
import NextLink from "next/link";

function Error404() {
  const intl = useIntl();

  return (
    <PageLayout
      title={intl.formatMessage({ defaultMessage: "Erreur 404" })}
      description={intl.formatMessage({
        defaultMessage: "Cette page est introuvable ou n'existe plus",
      })}>
      <Box pb="8.5rem">
        <Splash
          src={generateFrontPath({
            id: "@front.internal-assets",
            parameters: { filePath: "illustrations/no-results.svg" },
          })}
          title={intl.formatMessage({ defaultMessage: "Page introuvable" })}
          description={intl.formatMessage(
            {
              defaultMessage: `Nous sommes désolés, cette page n'existe pas ou a été supprimée.{br}Veuillez vérifier son adresse ou retourner à l'accueil.`,
            },
            { br: <br /> },
          )}
          addon={
            <VStack>
              <NextLink
                href={generateFrontPath({ id: "@front.home" })}
                passHref>
                <Button as="a" height="2.875rem" colorScheme="brandPrimary">
                  <FormattedMessage defaultMessage="Retourner à l'accueil" />
                </Button>
              </NextLink>
            </VStack>
          }
        />
      </Box>
    </PageLayout>
  );
}

export default Error404;
