import { Image, Text, VStack } from "@chakra-ui/react";
import ImageLoaderHandler from "@raiden/library/components/ImageLoaderHandler";
import { useIntl } from "react-intl";

/**
 * @typedef {object} Props
 * @property {string} title
 * @property {string} [src]
 * @property {string | import("react").ReactNode} [description]
 * @property {React.ReactNode} [addon]
 *
 * @param {Props} props
 */
export function Splash({ title, src, description, addon }) {
  const intl = useIntl();

  return (
    <VStack spacing="1.5rem">
      {src && (
        <ImageLoaderHandler src={src}>
          {({ ref, src, isLoaded, handleLoad }) => (
            <Image
              ref={ref}
              src={src}
              opacity={isLoaded ? 1 : 0}
              onLoad={handleLoad}
              transition="opacity .25s ease"
              alt={intl.formatMessage({ defaultMessage: "Splash" })}
            />
          )}
        </ImageLoaderHandler>
      )}

      <Text variant="h3" textAlign="center" color="brandPrimary.500">
        {title}
      </Text>

      {description && (
        <Text
          textAlign="center"
          variant="text-large"
          colorScheme="gray"
          whiteSpace="pre-wrap"
          w="full"
          color="brandPrimary.400">
          {description}
        </Text>
      )}

      {addon && addon}
    </VStack>
  );
}
